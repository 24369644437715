import { DialogActions, FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ActionButton, ThemeButton } from '../../components/button';
import FormInput from '../../components/formComponents/FormInput';
import Iconify from '../../components/Iconify';
import ModalComponent from '../../components/ModalComponent';
import CustomSelect from '../../components/select';
import { massEditObservation } from './studiesSlice';
import { filterEmptyValues } from '../../utils';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';

const SingleStudyToolbar = (props) => {
  const {
    setFilterName,
    selected,
    handleSelectionClick,
    filterName,
    onFilterName,
    handleClearSelection,
    toolbarProps,
    visibleRows,
    rows,
  } = props;
  const numSelected = selected?.length;
  const {
    onAddClick,
    handleEcElement,
    isEcValid,
    studyType,
    taskSelectOptions,
    rolesSelectOptions,
    elementSelectOptions,
    areasOptions,
    ratingOptions,
    fetchStudy,
  } = toolbarProps;
  const { addEcLoading, isMassLoading } = useSelector((state) => state.studies);
  const [isModalopen, setisModalopen] = useState(false);

  const form = useForm({
    defaultValues: {
      areaID: '',
      elementID: '',
      taskID: '',
      roleID: '',
      rating: '',
      frequency: null,
      notes: '',
      duration: '',
    },
    // resolver: yupResolver(addObservationSchema),
  });
  const params = useParams();
  const { id } = params;
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    form.reset();
  };
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    if (rows && rows?.length > 0 && selected && selected?.length > 0) {
      const payload = selected?.map((selectedId) => {
        const observation = rows?.find((row) => row?._id === selectedId) || null;
        return observation;
      });
      const finalPayload = payload?.map((observation) => {
        const obsObject = {
          studyType: observation?.studyType,
          studyID: observation?.studyID,
          _id: observation?._id,
          projectID: id,
          frequency: Number(values?.frequency) || Number(observation?.frequency) || 0,
          elementID: values?.elementID || observation?.elementID,
          taskID: studyType === 1 ? null : values?.taskID || observation?.taskID,
          roleID: studyType === 1 ? values?.roleID || observation?.roleID : null,
          areaID: values?.areaID || observation?.areaID,
          duration: values?.duration || observation?.duration,
          notes: values?.notes || observation?.notes,
          rating: values?.rating || observation?.rating,
          roundID: studyType === 1 ? observation?.roundID : null,
        };
        const filteredPayload = filterEmptyValues(obsObject);
        return filteredPayload;
      });
      dispatch(massEditObservation(finalPayload))
        .then((response) => {
          if (response?.payload.successCount <= 0) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
          } else {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
          }
          closeModal();
          handleClearSelection();
          fetchStudy();
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: 'Something went wrong',
            })
          );
        });
    }
  };
  const currentTask = watch('taskID');
  const { tasks, isLoading: isTaskLoading } = useSelector((state) => state.tasks);
  const currentTaskElements = useMemo(() => {
    if (!currentTask || !tasks?.length || !elementSelectOptions?.length) {
      return [];
    }
    const currentTaskObject = tasks?.find((el) => el?._id === currentTask);

    let filteredElements = [];
    if (currentTaskObject) {
      // filteredElements = elementSelectOptions?.filter((op) =>
      //   currentTaskObject?.projectSetting?.elements?.some((el) => el === op?.value)
      // );
      filteredElements = currentTaskObject?.projectSetting?.elements.map((newElement) =>
        elementSelectOptions.find((el) => newElement === el?.value)
      );
    }
    return filteredElements.filter((element) => element !== '' && element !== null && element !== undefined);
  }, [currentTask, elementSelectOptions, tasks]);
  const frequencyInputFields = register('frequency');
  const frequencyValue = watch('frequency');
  return (
    <>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '600px !important',
            minHeight: '78vh',
          },
        }}
        title="Create Element"
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton onClick={form?.handleSubmit(onSubmit)} type="submit" loading={isMassLoading} variant="contained">
              Save Changes
            </ThemeButton>
          </DialogActions>
        }
      >
        <form>
          <Grid mt rowSpacing={1} columnSpacing={3} container>
            {studyType !== 1 ? (
              <Grid item xs={12} md={12}>
                <label htmlFor="taskID" style={{ fontSize: '0.8600rem' }}>
                  Task
                </label>
                <CustomSelect
                  name="taskID"
                  id="taskID"
                  label="Select Task"
                  control={control}
                  options={taskSelectOptions}
                  error={!!errors?.taskID}
                  helperText={errors?.taskID && errors?.taskID?.message}
                  isLoading
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={12}>
                <label htmlFor="roleID" style={{ fontSize: '0.8600rem' }}>
                  Role
                </label>
                <CustomSelect
                  id="roleID"
                  name="roleID"
                  label="Select Role"
                  control={control}
                  options={rolesSelectOptions}
                  error={!!errors?.roleID}
                  helperText={errors?.roleID && errors?.roleID?.message}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <label htmlFor="areaID" style={{ fontSize: '0.8600rem' }}>
                Area
              </label>
              <CustomSelect
                name="areaID"
                label="Select Area"
                control={control}
                options={areasOptions}
                error={!!errors?.areaID}
                helperText={errors?.areaID && errors?.areaID?.message}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <label htmlFor="rating" style={{ fontSize: '0.8600rem' }}>
                Rating
              </label>
              <CustomSelect
                id="rating"
                name="rating"
                label="Select Rating"
                control={control}
                options={ratingOptions}
                error={!!errors?.rating}
                helperText={errors?.rating && errors?.rating?.message}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack width="100%" direction="column">
                <Stack alignItems="center" direction="row">
                  <label htmlFor="elementID" style={{ fontSize: '0.8600rem' }}>
                    Element
                  </label>
                </Stack>
                <CustomSelect
                  id="elementID"
                  name="elementID"
                  label="Select Element"
                  control={control}
                  options={studyType !== 1 ? currentTaskElements : elementSelectOptions}
                  error={!!errors?.elementID}
                  helperText={errors?.elementID && errors?.elementID?.message}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormInput
                label="Notes"
                placeholder="Write your notes"
                InputProps={{ ...register('notes') }}
                error={!!errors?.notes}
                helperText={errors?.notes && errors?.notes?.message}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormInput
                label="Frequency"
                type="number"
                placeholder="Write frequency"
                InputProps={{
                  name: frequencyInputFields.name,
                  onblur: frequencyInputFields.onBlur,
                  ref: frequencyInputFields.ref,
                  value: frequencyValue,
                  onChange: (event) => {
                    const newValue = event.target.value;
                    if (newValue === '' || Number(newValue) >= 0) {
                      return frequencyInputFields.onChange(event);
                    }
                    return null;
                  },
                }}
                error={!!errors?.frequency}
                helperText={errors?.frequency && errors?.frequency?.message}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
      <Toolbar
        sx={{
          minHeight: 76,
          py: 2,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between',
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
          }),
        }}
      >
        {/* {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : null} */}

        {numSelected > 0 ? (
          <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
            </Typography>
            <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
          </Stack>
        ) : null}

        {numSelected > 0 ? (
          <Stack gap direction="row" whiteSpace="nowrap">
            <ThemeButton
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              color="primary"
              onClick={() => setisModalopen(true)}
            >
              {`Mass Edit ${numSelected > 1 ? 'all' : ''}`}
            </ThemeButton>
            <ActionButton
              type="archive"
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              color="error"
              // onClick={() => handleSelectionClick && handleSelectionClick(selected, 'delete', handleClearSelection)}
            >
              {`Delete ${numSelected > 1 ? 'all' : ''}`}
            </ActionButton>
            {isEcValid && (
              <ActionButton
                type=""
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                disabled={!isEcValid(selected, visibleRows)}
                variant="outlined"
                loading={addEcLoading}
                onClick={() => handleEcElement && handleEcElement(selected, visibleRows, handleClearSelection)}
              >
                EC Element
              </ActionButton>
            )}
          </Stack>
        ) : (
          <Grid p={1} container justifyContent="space-between">
            <Grid item lg={3} md={4.5} xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  // size='small'
                  sx={{
                    fontSize: '0.86rem',
                    // mt: '5px',
                    // mb: '16px',
                    borderRadius: 25,
                    lineHeight: '1.9375em',
                    '.MuiInputBase-input': {
                      height: '0.660em',
                      py: '15px',
                    },
                  }}
                  fullWidth
                  // size='small'
                  value={filterName}
                  onChange={onFilterName}
                  placeholder={get(toolbarProps, 'searchLable') || 'Search Studies'}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} xl={5} lg={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <ThemeButton
                onClick={() => {
                  toolbarProps?.setFilterConfig({
                    filterBy: '',
                    filterColumns: [],
                    sortOrder: '',
                    sortBy: '',
                  });
                  setFilterName('');
                }}
                size="small"
                variant="contained"
              >
                Clear Filter
              </ThemeButton>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </>
  );
};

SingleStudyToolbar.propTypes = {
  selected: PropTypes.array,
  visibleRows: PropTypes.array,
  rows: PropTypes.array,
  handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleClearSelection: PropTypes.func,
  setFilterName: PropTypes.func,
  toolbarProps: PropTypes.object,
};

export default SingleStudyToolbar;
