import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { ActionButton, ThemeButton } from '../../components/button';
import StatusButtons from '../../components/buttonGroup';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import axiosInstance from '../../utils/axiosInstance';
import { getTasks } from './taskSlice';

const TasksToolbar = (props) => {
  const {
    selected,
    filterName,
    handleClearSelection,
    onFilterName,
    searchLable,
    toolbarProps,
    setPage,
    handleOpenBackdrop,
    handleCloseBackdrop,
  } = props;
  const numSelected = selected?.length;
  const { onCancel, onSave } = toolbarProps;
  const params = useParams();
  const dispatch = useDispatch();
  const { id } = params;
  const handleSelectionClick = async (selectedIds, status) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (selectedIds && selectedIds?.length > 0) {
      const initialPayload = { taskIDs: selected, status, projectID: id };
      handleOpenBackdrop();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'tasks/update/status',
          method: 'PATCH',
          data: initialPayload,
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
              // snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Area`,
            })
          );
          handleClearSelection();
          dispatch(getTasks({ projectID: id }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const handleStatusChange = (event) => {
    toolbarProps?.onStatusChange(event);
    setPage(0);
  };
  return (
    <Toolbar
      sx={{
        minHeight: 76,
        py: 2,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
          </Typography>
          <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
        </Stack>
      ) : null}

      {numSelected > 0 ? (
        <Stack gap direction="row" whiteSpace="nowrap">
          <ActionButton
            type="activate"
            sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
            size="large"
            variant="outlined"
            onClick={() => handleSelectionClick && handleSelectionClick(selected, 'active')}
          >
            Activate all
          </ActionButton>
          <ActionButton
            type="archive"
            sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
            size="large"
            variant="outlined"
            color="error"
            onClick={() => handleSelectionClick && handleSelectionClick(selected, 'archive')}
          >
            Archive all
          </ActionButton>
        </Stack>
      ) : (
        <Grid p={1} container alignItems="center" justifyContent="space-between" spacing>
          <Grid item lg={5} xl={4} md={6} xs={12}>
            <Stack
              gap={2}
              width="100%"
              sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
              direction="row"
              alignItems="center"
            >
              <FormControl fullWidth>
                <OutlinedInput
                  // size='small'
                  sx={{
                    fontSize: '0.86rem',
                    // mt: '5px',
                    // mb: '16px',
                    borderRadius: 25,
                    lineHeight: '1.9375em',
                    '.MuiInputBase-input': {
                      height: '0.860em',
                      py: '15px',
                    },
                  }}
                  fullWidth
                  // size='small'
                  value={filterName}
                  onChange={onFilterName}
                  placeholder={searchLable || 'Search Tasks'}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
              {toolbarProps?.showReorderButton ? (
                <>
                  {!toolbarProps?.isReording ? (
                    <ThemeButton
                      onClick={toolbarProps?.hadleReorderClick}
                      sx={{ width: { sm: '50%', xs: '40%' } }}
                      variant="outlined"
                    >
                      ReOrder Tasks
                    </ThemeButton>
                  ) : (
                    <Stack direction="row" spacing>
                      <ThemeButton sx={{ px: 3 }} onClick={onSave} variant="contained">
                        save
                      </ThemeButton>
                      <ThemeButton onClick={onCancel} variant="outlined" color="error">
                        Cancel
                      </ThemeButton>
                    </Stack>
                  )}
                </>
              ) : null}
            </Stack>
          </Grid>
          <Grid justifyContent={{ lg: 'flex-start', xl: 'flex-end' }} item xs={12} md={6} xl={4} lg={6}>
            <Stack
              gap={2}
              width="100%"
              sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
              direction="row"
              alignItems="center"
            >
              {/* <Typography>Status</Typography> */}
              <ThemeButton
                onClick={toolbarProps?.openModal}
                startIcon={<AddCircleOutlineIcon />}
                sx={{ width: { sm: '50%', xs: '50%' } }}
                variant="outlined"
              >
                Create Task
              </ThemeButton>
              <StatusButtons
                status={toolbarProps?.status}
                onStatusChange={handleStatusChange}
                fullWidth
                size="small"
                // sx={{ width: '80%', mt: '5px', mb: '16px' }}
                sx={{ width: '90%' }}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

TasksToolbar.propTypes = {
  selected: PropTypes.array,
  handleClearSelection: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  searchLable: PropTypes.string,
  setPage: PropTypes.func,
  toolbarProps: PropTypes.object,
  handleOpenBackdrop: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
};

export default TasksToolbar;
