import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Container, DialogActions } from '@mui/material';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../components/ModalComponent';
import { ThemeButton } from '../../../components/button';
import GroupCheckbox from '../../../components/formComponents/FormCheckbox';
import FormInput from '../../../components/formComponents/FormInput';
import FormRadioGroup from '../../../components/formComponents/formRadioGroup';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import StatusIndicator from '../../../components/status';
import DataTable from '../../../components/table';
import { Types } from '../../../types';
import { analysisTypeLabel } from '../../../utils';
import axiosInstance from '../../../utils/axiosInstance';
import { addCategorySchema } from '../../../utils/schema';
import MainCategoryLabel from '../../admin/category/MainCategoryLabel';
import { getCustomers } from '../../customer/customerSlice';
import { addCategory, editCategory, getCategories } from './projectCategoriesSlice';

const CategoriesPage = ({ projectID, customerID }) => {
  const dispatch = useDispatch();
  // dispatch(getCategories({ defaultEntry: true }));
  const headCells = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      minWidth: 200,
      // titleCase: true,
    },
    {
      id: 'analysisLabel',
      label: 'Analysis Type',
      align: 'left',
      minWidth: 200,
      titleCase: true,
    },
    {
      id: 'categoriesName',
      label: 'Main Category',
      align: 'left',
      minWidth: 200,
      renderElement: (cell) => <MainCategoryLabel category={get(cell, 'projectSetting.mainCategory')} />,
      // titleCase: true,
    },
    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => <StatusIndicator cell={cell} />,
      width: '15%',
    },
  ];
  const [rowToEdit, setRowToEdit] = useState('');
  const form = useForm({
    defaultValues: {
      defaultEntry: false,
      name: '',
      analysisType: null,
      mainCategory: null,
    },
    resolver: yupResolver(addCategorySchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const [isModalopen, setisModalopen] = useState(false);
  const { projectCategories, isLoading, isAdding } = useSelector((state) => state.projectCategories);

  useEffect(() => {
    dispatch(getCategories({ defaultEntry: false, projectID }));
  }, [dispatch, projectID]);
  const fetchData = () => {
    dispatch(getCategories({ defaultEntry: false, projectID }));
  };
  const regularCategories = useMemo(
    () =>
      !isEmpty(projectCategories) &&
      projectCategories.reduce(
        (acc, category) => {
          const fullCategory = {
            ...category,
            categoriesName: get(category, 'projectSetting.mainCategory'),
            status: get(category, 'projectSetting.status'),
            analysisLabel: analysisTypeLabel(get(category, 'projectSetting.analysisType')),
          };
          if (category?.projectSetting?.status === 'active') {
            acc.active.push(fullCategory);
          } else if (category?.projectSetting?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(fullCategory);
          }
          acc.default.push(fullCategory);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [projectCategories]
  );
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [Status, setStatus] = useState(ACTIVE);
  const [categoryRows, setCategoryRows] = useState([]);

  const analysisOptions = [
    { label: 'Value Analysis', value: 1 },
    { label: 'Timeline Only', value: 2 },
  ];
  const mainCategoryOptions = [
    { label: 'Customer', value: 1 },
    { label: 'Task & Process', value: 2 },
    { label: 'NVA', value: 3 },
  ];

  useEffect(() => {
    if (regularCategories) {
      if (Status === DEFAULT) {
        setCategoryRows(regularCategories?.default);
      } else if (Status === ARCHIVED) {
        setCategoryRows(regularCategories?.archive);
      } else if (Status === ACTIVE) {
        setCategoryRows(regularCategories?.active);
      }
    } else {
      setCategoryRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularCategories]);

  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setCategoryRows(regularCategories.default);
      } else if (value === ACTIVE) {
        setCategoryRows(regularCategories.active);
      } else if (value === ARCHIVED) {
        setCategoryRows(regularCategories.archive);
      }
    }
  };

  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    // setisModalopen(false);
    reset();
    // reset({ permission: '' });
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const onSubmit = (values) => {
    if (customerID && projectID && values) {
      if (get(values, 'analysisType') && get(values, 'mainCategory')) {
        values.analysisType = parseInt(values.analysisType, 10);
        values.mainCategory = parseInt(values.mainCategory, 10);
      }
      if (rowToEdit) {
        dispatch(editCategory({ ...values, _id: rowToEdit, projectID, customerID, defaultEntry: false })).then((response) => {
          if (response.payload?.success) {
            closeModal();
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message' || 'Category Updated Successfully'),
              })
            );
            dispatch(getCategories({ defaultEntry: false, projectID }));
          } else {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        });
      } else {
        dispatch(addCategory({ ...values, projectID, customerID }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Category Added Successfully'),
                })
              );
              dispatch(getCategories({ defaultEntry: false, projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };

  const openModal = () => setisModalopen(true);
  const getPayload = (selected, status, id) => ({ categoryIDs: selected, status, projectID: id, defaultEntry: false });

  const toolbarProps = {
    status: Status,
    onStatusChange,
    openModal,
    placeholder: 'Search Category',
    addButtonName: 'Add Category',
    getPayload,
    fetchData,
  };
  const onEditClick = async (event, row) => {
    // setRowToEdit((row?._id));
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      const categoryToEdit = projectCategories.find((category) => category?._id === row?._id);
      if (categoryToEdit) {
        setValue('name', get(categoryToEdit, 'name', ''));
        setValue('analysisType', get(categoryToEdit, 'projectSetting.analysisType', ''));
        setValue('mainCategory', get(categoryToEdit, 'projectSetting.mainCategory', ''));
      }
      openModal();
    } else {
      console.warn('No Row Id found');
    }
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'categories/update/status',
          method: 'PATCH',
          data: { categoryIDs: [row?._id], status, defaultEntry: false, projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Category`,
            })
          );
          dispatch(getCategories({ defaultEntry: false, projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `categories/delete/${row?._id}`,
          method: 'DELETE',
          data: { defaultEntry: false, projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getCategories({ defaultEntry: false, projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  // const hideEditButton = (row) => row?.defaultEntry;
  const hideEditButton = false;

  return (
    <Box>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px !important',
          },
        }}
        title={rowToEdit ? 'Edit Category' : 'Create Category'}
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <FormInput
              label="Category Name"
              disabled={rowToEdit}
              placeholder="Enter Name"
              InputProps={{
                ...register('name'),
              }}
              error={!!errors?.name}
              helperText={errors?.name && errors?.name?.message}
            />
          </Box>
          <Box
            sx={{
              '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
              '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
              '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
              '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
              mt: 1,
            }}
          >
            <GroupCheckbox
              error={!!errors?.analysisType}
              helperText={errors?.analysisType && errors?.analysisType?.message}
              label="Analysis Type"
            >
              <FormRadioGroup fontSize={18} options={analysisOptions} control={control} name="analysisType" />
            </GroupCheckbox>
          </Box>
          <Box
            sx={{
              '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
              '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
              '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
              '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
              mt: 1,
            }}
          >
            <GroupCheckbox
              error={!!errors?.mainCategory}
              helperText={errors?.mainCategory && errors?.mainCategory?.message}
              label="Main Category"
            >
              <FormRadioGroup fontSize={18} options={mainCategoryOptions} control={control} name="mainCategory" />
            </GroupCheckbox>
          </Box>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isAdding} type="submit" variant="contained">
              {rowToEdit ? 'Save Changes' : 'Create Category'}
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        onEditClick={onEditClick}
        onRowStatusChange={onRowStatusChange}
        defaultRowsPerPage={10}
        maxHeight={653}
        filterKeys={['name']}
        customToolbar={Types.toolbarTypes.CATEGORY}
        toolbarProps={toolbarProps}
        hideEditButton={hideEditButton}
        statusUpdateUrl="categories/update/status"
        // handleSelectionClick={handleSelectionClick}
        rows={categoryRows}
        stickyHeader
        onRowDelete={onDelete}
      />
    </Box>
  );
};

export default CategoriesPage;
CategoriesPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};
