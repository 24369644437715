import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, DialogActions, FormLabel, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { get, has } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../components/ModalComponent';
import { ThemeButton } from '../../../components/button';
import ThemeCheckbox from '../../../components/checkbox/themeChekbox';
import CustomSelect from '../../../components/select';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import DataTable from '../../../components/table';
import axiosInstance from '../../../utils/axiosInstance';
import { addProjectFielduserSchema } from '../../../utils/schema';
import { editFieldUser, getFieldUsers } from '../../user/field/fieldUserSlice';
import { addFieldUser, getProjectFieldUsers, updateFieldUser } from './fieldUserSlice';
import { getLocations } from '../location/locationSlice';

const FieldUser = ({ projectID, customerID, customerName, project }) => {
  const dispatch = useDispatch();
  const { projectFieldUsers, isLoading, addUserLoading } = useSelector((state) => state.projectUsers);
  const { isUserAdding } = useSelector((state) => state.fieldUser);
  const headCells = [
    {
      id: 'fullName',
      numeric: false,
      // disablePadding: true,
      label: 'Name',
      titleCase: true,
      renderElement: (cell) => (
        <Stack>
          <Typography>{cell?.fullName}</Typography>
          <Typography variant="caption" sx={{ color: '#3c4555' }}>
            {cell?.locations &&
              cell?.locations.length > 0 &&
              cell.locations.map((location, index) => `${index !== 0 ? ', ' : ''}${location?.name} `)}
          </Typography>
        </Stack>
      ),
      // align: 'left',
    },
    {
      id: 'averageHours',
      disablePadding: false,
      label: 'Average Hrs ',
    },
    {
      id: 'studyDays',
      disablePadding: false,
      label: 'Study Days',
    },
    {
      id: 'totalHours',
      disablePadding: false,
      label: 'Total Hrs ',
    },
    {
      id: 'dotsHours',
      disablePadding: false,
      label: 'Activity Hrs',
    },
    {
      id: 'rasHours',
      disablePadding: false,
      label: 'Efficiency Hrs',
    },
    {
      id: 'diloHours',
      disablePadding: false,
      label: 'Role Hrs',
    },
    {
      id: 'observations',
      disablePadding: false,
      label: 'Observations',
    },
  ];
  const [isModalopen, setisModalopen] = useState(false);
  const [isOtherUsersModelOpen, setIsOtherUsersModelOpen] = useState(false);
  const [fieldUserOptions, setFieldUserOptions] = useState([]);
  const [otherUserOptions, setOtherUserOptions] = useState([]);
  const [unAssignedUsers, setUnAssignedUsers] = useState([]);
  const [currentUserOption, setCurrentUserOption] = useState(null);
  const [rowToEdit, setRowToEdit] = useState('');
  const userRows = useMemo(() => {
    if (projectFieldUsers && projectFieldUsers?.length) {
      return projectFieldUsers.map((user) => ({
        ...user,
        _id: user?.userID,
        fullName: `${get(user, 'name', '')} ${get(user, 'lastname', '')}`,
      }));
    }
    return [];
  }, [projectFieldUsers]);
  const closeOtherUserModel = () => {
    setIsOtherUsersModelOpen(false);
  };
  const openOtherUserModel = () => {
    setIsOtherUsersModelOpen(true);
  };
  const getFieldUserOptions = async (customerId, projectId) => {
    try {
      const response = await axiosInstance({
        method: 'POST',
        url: 'users/get',
        data: { role: 3, customerID: customerId, projectID: projectId },
      });
      const data = await response.data;
      if (data?.success && data?.data?.length >= 0) {
        const options = data.data?.map((user) => ({
          label: `${get(user, 'name', '')} ${get(user, 'lastname', '')}`,
          value: user?._id,
        }));
        setFieldUserOptions(options);
      }
    } catch (error) {
      console.log('error -> ', error);
      setFieldUserOptions([]);
    }
  };
  const form = useForm({
    defaultValues: {
      locations: [],
      userID: '',
      areaCreationDILO: false,
      areaCreationDOTS: false,
      areaCreationRAS: false,
      elementCreationDILO: false,
      elementCreationDOTS: false,
      elementCreationRAS: false,
      roleCreationDILO: false,
      roleCreationRAS: false,
    },
    // resolver: rowToEdit ? yupResolver(editIndustrySchema) : yupResolver(addIndustrySchema),
    resolver: yupResolver(addProjectFielduserSchema),
  });
  const [unAssignedUser, setUnAssignedUser] = useState('');
  const [unAssignedErrorMessage, setUnassignedErrorMessage] = useState('');
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'locations', // unique name for your Field Array
  });
  const { errors } = formState;
  const handleUnAssignedUser = (event) => {
    if (event.target?.value) {
      setUnAssignedUser(event.target.value);
      setUnassignedErrorMessage('');
    }
  };
  useEffect(() => {
    if (customerID) {
      getFieldUserOptions(customerID, projectID);
    }
  }, [customerID, projectID]);
  const getUnassignedUsers = useCallback(() => {
    if (customerID) {
      dispatch(getFieldUsers({ customerID, projectID, isUnassignedFieldUsers: true })).then((res) => {
        if (get(res, 'payload.success') && res?.payload?.data?.length >= 0) {
          setUnAssignedUsers(res?.payload?.data);
          const options =
            res.payload.data &&
            res.payload.data
              .filter((user) => user.status === 'active')
              .map((user) => ({ label: `${get(user, 'name', '')} ${get(user, 'lastname', '')}`, value: get(user, '_id') }));
          setOtherUserOptions(options);
        }
      });
    }
  }, [customerID, projectID, dispatch]);
  useEffect(() => {
    getUnassignedUsers();
  }, [customerID, dispatch, getUnassignedUsers]);

  useEffect(() => {
    if (projectID) {
      dispatch(getProjectFieldUsers({ projectID }));
      dispatch(getLocations({ projectID }));
    }
  }, [dispatch, projectID]);
  const { locations } = useSelector((state) => state.location);
  const locationOptions = useMemo(
    () =>
      locations
        ?.filter((currentLocation) => get(currentLocation, 'projectSetting.status') === 'active')
        ?.map((location) => {
          const label = get(location, 'name', '');
          // {
          //   cell?.locations &&
          //     cell?.locations.length > 0 &&
          //     cell.locations.map((location, index) => `${index !== 0 ? ', ' : ''}${location?.name} `);
          // }
          return { label, value: location?._id };
        }),
    [locations]
  );
  const openModal = () => setisModalopen(true);
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    reset();
    setUnAssignedUser('');
    setCurrentUserOption(null);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const onSubmit = (values) => {
    if (projectID) {
      if (rowToEdit) {
        dispatch(updateFieldUser({ ...values, projectID, userID: rowToEdit }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Field User Added Successfully'),
                })
              );
              dispatch(getProjectFieldUsers({ projectID }));
              if (customerID) {
                getFieldUserOptions(customerID, projectID);
                getUnassignedUsers();
              }
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      } else {
        dispatch(addFieldUser({ ...values, projectID }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Field User Added Successfully'),
                })
              );
              dispatch(getProjectFieldUsers({ projectID }));
              if (customerID) {
                getFieldUserOptions(customerID, projectID);
                getUnassignedUsers();
              }
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const handleUpdateUser = async () => {
    if (unAssignedUser) {
      setUnassignedErrorMessage('');
      const user = unAssignedUsers?.find((field) => field?._id === unAssignedUser);
      // user.canBeUsedByCustomers = user?.canBeUsedByCustomers?.length ? [...user.canBeUsedByCustomers, customerID] : [customerID];
      const oldUsers = user?.canBeUsedByCustomers && user?.canBeUsedByCustomers?.length ? user.canBeUsedByCustomers : [];
      dispatch(
        editFieldUser({
          ...user,
          canBeUsedByCustomers: [...oldUsers, customerID],
          isUnassignedFieldUsers: true,
        })
      ).then((res) => {
        if (get(res, 'payload.success')) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(res, 'payload.message' || 'User Updated Successfully'),
            })
          );
          if (customerID) {
            getFieldUserOptions(customerID, projectID);
            if (customerID) {
              getFieldUserOptions(customerID, projectID);
              getUnassignedUsers();
            }
          }
          setIsOtherUsersModelOpen(false);
          setUnAssignedUser('');
        } else {
          const errorMessage = get(res, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      });
      // const response = await axiosInstance({})
    } else {
      setUnassignedErrorMessage('Field User is required');
    }
  };
  const handleAddLocation = (event) => {
    if (event.target?.value) {
      append(event.target.value);
    }
  };
  const onEditClick = async (event, row) => {
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      openModal();
      try {
        const response = await axiosInstance({
          url: 'projects/users',
          method: 'POST',
          data: { projectID, userID: row?._id },
        });
        if (get(response, 'data.data', '')) {
          setValue('userID', get(response, 'data.data.userID', ''));
          if (get(response, 'data.data.userID')) {
            setCurrentUserOption({
              label: `${get(response, 'data.data.name', '')} ${get(response, 'data.data.lastname', '')}`,
              value: get(response, 'data.data.userID', ''),
            });
          }

          setValue('areaCreationDILOS', get(response, 'data.data.areaCreationDILOS'));
          setValue('areaCreationDILO', get(response, 'data.data.areaCreationDILO'));
          setValue('areaCreationDOTS', get(response, 'data.data.areaCreationDOTS'));
          setValue('areaCreationRAS', get(response, 'data.data.areaCreationRAS'));
          setValue('elementCreationDILOS', get(response, 'data.data.elementCreationDILOS'));
          setValue('elementCreationDILO', get(response, 'data.data.elementCreationDILO'));
          setValue('elementCreationDOTS', get(response, 'data.data.elementCreationDOTS'));
          setValue('elementCreationRAS', get(response, 'data.data.elementCreationRAS'));
          setValue('roleCreationDILOS', get(response, 'data.data.roleCreationDILOS'));
          setValue('roleCreationDILO', get(response, 'data.data.roleCreationDILO'));
          setValue('roleCreationRAS', get(response, 'data.data.roleCreationRAS'));
          const locaionObjs = get(response, 'data.data.locations', '');
          const locationIds = locaionObjs?.length >= 0 && locaionObjs.map((obj) => obj?._id);
          if (locationIds) {
            setValue('locations', locationIds);
          }
        }
      } catch (error) {
        console.log('error -> getUsersById');
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const toolbarProps = {
    onAddClick: openModal,
    searchLable: 'Search Users',
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: 'projects/delete/user',
          method: 'DELETE',
          data: { projectID, userIDs: [row?._id] },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getProjectFieldUsers({ projectID }));
          if (customerID) {
            getFieldUserOptions(customerID, projectID);
            getUnassignedUsers();
          }
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const locationValues = watch('locations');
  const getModalTitle = () => {
    if (isOtherUsersModelOpen) {
      return 'Unassigned Field User';
    }
    if (rowToEdit) {
      return 'Edit Field User';
    }
    return 'Add Field User';
  };
  return (
    <Box>
      {/* <Typography sx={{ fontSize: '1.4rem', fontWeight: 400, color: '#3c4555' }}>Field Users</Typography> */}
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '700px !important',
          },
        }}
        // title={rowToEdit ? 'Edit Industry' : 'Create Industry'}
        title={getModalTitle()}
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            {isOtherUsersModelOpen ? (
              <Grid item xs={12} md={6}>
                {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                <label style={{ fontSize: '0.8600rem' }}>Name</label>
                <CustomSelect
                  value={unAssignedUser}
                  onChange={handleUnAssignedUser}
                  options={otherUserOptions}
                  label="Select Field User"
                  error={!!unAssignedErrorMessage}
                  helperText={unAssignedErrorMessage}
                />
              </Grid>
            ) : (
              <Grid columnSpacing={3} rowSpacing={2} container justifyContent="space-between">
                <Grid item xs={12} md={6}>
                  {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                  <label style={{ fontSize: '0.8600rem' }}>Name</label>
                  <CustomSelect
                    name="userID"
                    control={control}
                    options={rowToEdit ? [...fieldUserOptions, currentUserOption && currentUserOption] : fieldUserOptions}
                    label="Select Field User"
                    disabled={rowToEdit}
                    error={!!errors?.userID}
                    helperText={errors?.userID && errors?.userID?.message}
                  />
                </Grid>
                <Grid alignSelf="center" item lg={6} sm={12} xs={12} md={6}>
                  <Stack direction="row" alignItems="center" gap={1} mt={3}>
                    <ErrorOutlineIcon color="#3c4555" fontSize="small" />
                    <Typography variant="body2">
                      Field users assigned to <b style={{ fontWeight: 600 }}>{customerName} </b>customer
                    </Typography>
                  </Stack>
                </Grid>
                <Grid my={1} item lg={12} sm={12} xs={12} md={12}>
                  <Typography variant="body2">
                    <Link component="button" variant="body2" onClick={openOtherUserModel} sx={{ cursor: 'pointer' }}>
                      Click Here
                    </Link>{' '}
                    if you want to add others field users.
                  </Typography>
                  {/* <FormInput
                  label="Last Name"
                  placeholder="Enter Last Name"
                  InputProps={{ ...register('lastname') }}
                  error={!!errors?.lastname}
                  helperText={errors?.lastname && errors?.lastname?.message}
                /> */}
                </Grid>
                <Grid item lg={4} sm={12} xs={12} md={4}>
                  <FormLabel
                    sx={{
                      fontSize: '0.9375rem',
                      '&.MuiFormLabel-root': { color: '#212B36 !important' },
                      color: '#212B36',
                      fontWeight: 600,
                    }}
                    component="legend"
                  >
                    Allow Element Creation
                  </FormLabel>
                  <ThemeCheckbox
                    checkboxProps={{ disabled: has(project, 'elementCreationRAS') ? !get(project, 'elementCreationRAS') : true }}
                    label="Efficiency Study"
                    control={control}
                    name="elementCreationRAS"
                  />
                  <ThemeCheckbox
                    checkboxProps={{
                      disabled: has(project, 'elementCreationDILO') ? !get(project, 'elementCreationDILO') : true,
                    }}
                    label="Role Study"
                    control={control}
                    name="elementCreationDILO"
                  />
                  <ThemeCheckbox
                    checkboxProps={{
                      disabled: has(project, 'elementCreationDOTS') ? !get(project, 'elementCreationDOTS') : true,
                    }}
                    label="Activity  Study"
                    control={control}
                    name="elementCreationDOTS"
                  />
                </Grid>
                <Grid item lg={4} sm={12} xs={12} md={4}>
                  <FormLabel
                    sx={{
                      fontSize: '0.9375rem',
                      '&.MuiFormLabel-root': { color: '#212B36 !important' },
                      color: '#212B36',
                      fontWeight: 600,
                    }}
                    component="legend"
                  >
                    Allow Area Creation
                  </FormLabel>
                  <ThemeCheckbox
                    checkboxProps={{ disabled: has(project, 'areaCreationRAS') ? !get(project, 'areaCreationRAS') : true }}
                    label="Efficiency Study"
                    control={control}
                    name="areaCreationRAS"
                  />
                  <ThemeCheckbox
                    checkboxProps={{ disabled: has(project, 'areaCreationDILO') ? !get(project, 'areaCreationDILO') : true }}
                    label="Role Study"
                    control={control}
                    name="areaCreationDILO"
                  />
                  <ThemeCheckbox
                    checkboxProps={{ disabled: has(project, 'areaCreationDOTS') ? !get(project, 'areaCreationDOTS') : true }}
                    label="Activity  Study"
                    control={control}
                    name="areaCreationDOTS"
                  />
                </Grid>
                <Grid item lg={4} sm={12} xs={12} md={4}>
                  <FormLabel
                    sx={{
                      fontSize: '0.9375rem',
                      '&.MuiFormLabel-root': { color: '#212B36 !important' },
                      color: '#212B36',
                      fontWeight: 600,
                    }}
                    component="legend"
                  >
                    Allow Role Creation
                  </FormLabel>
                  <ThemeCheckbox
                    checkboxProps={{ disabled: has(project, 'roleCreationRAS') ? !get(project, 'roleCreationRAS') : true }}
                    label="Efficiency Study"
                    control={control}
                    name="roleCreationRAS"
                  />
                  <ThemeCheckbox
                    checkboxProps={{ disabled: has(project, 'roleCreationDILO') ? !get(project, 'roleCreationDILO') : true }}
                    label="Role Study"
                    control={control}
                    name="roleCreationDILO"
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  {fields.map((currentField, index) => {
                    const location = locations?.find((loc) => loc?._id === getValues(`locations[${index}]`));
                    return (
                      <Stack direction="row" p={1} my={1} bgcolor="#f6f6f6" justifyContent="space-between" alignItems="center">
                        <Stack>
                          <Typography variant="body2">{get(location, 'name', '')}</Typography>
                          <Typography variant="caption">{get(location, 'projectSetting.address', '')}</Typography>
                        </Stack>
                        <IconButton>
                          <ClearIcon onClick={() => remove(index)} fontSize="small" />
                        </IconButton>
                      </Stack>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                  <label style={{ fontSize: '0.8600rem' }}>Locations</label>
                  <CustomSelect
                    // value={unAssignedUser}
                    value=""
                    onChange={handleAddLocation}
                    options={locationOptions}
                    isDisabled={(option) => {
                      if (!option?.value) {
                        return false;
                      }
                      if (locationValues?.includes(option?.value)) {
                        return true;
                      }
                      return false;
                    }}
                    label="Select to add a location"
                    error={!!errors?.locations}
                    helperText={errors?.locations && errors?.locations?.message}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton
              type="button"
              onClick={isOtherUsersModelOpen ? closeOtherUserModel : closeModal}
              color="error"
              variant="outlined"
            >
              Cancel
            </ThemeButton>
            {isOtherUsersModelOpen ? (
              <ThemeButton
                loading={isUserAdding}
                type="button"
                disabled={!customerID}
                onClick={handleUpdateUser}
                variant="contained"
              >
                {/* {rowToEdit ? 'Save Changes' : 'Create industry'} */}
                Save
              </ThemeButton>
            ) : (
              <ThemeButton loading={addUserLoading} type="submit" variant="contained">
                {/* {rowToEdit ? 'Save Changes' : 'Create industry'} */}
                Save Changes
              </ThemeButton>
            )}
          </DialogActions>
        </form>
      </ModalComponent>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        rows={userRows}
        onEditClick={onEditClick}
        // handleSelectionClick={handleSelectionClick}
        filterKeys={['fullName']}
        handleRowClick={onEditClick}
        enableDelete
        disableCheckbox
        customToolbar="fieldUser"
        maxHeight={453}
        toolbarProps={toolbarProps}
        onRowDelete={onDelete}
        defaultRowsPerPage={10}
      />
      <DevTool control={control} />
    </Box>
  );
};

// const FieldUser = (props) => <Tabtitle title="Field Users Tab" />;

FieldUser.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
  customerName: PropTypes.string,
  project: PropTypes.object,
};

export default FieldUser;
