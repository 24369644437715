import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DialogActions, FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { jwtDecode } from 'jwt-decode';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Iconify from '../../../components/Iconify';
import ModalComponent from '../../../components/ModalComponent';
import { ActionButton, ThemeButton } from '../../../components/button';
import StatusButtons from '../../../components/buttonGroup';
import CustomSelect from '../../../components/select';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import { Types } from '../../../types';
import axiosInstance from '../../../utils/axiosInstance';
import { addElement } from '../elements/elementsSlice';
import { getCategories } from '../../admin/category/categoriesSlice';
import { addCategory } from './projectCategoriesSlice';

const CategoriesToolbar = (props) => {
  const {
    selected,
    filterName,
    onFilterName,
    searchLable,
    toolbarProps,
    handleClearSelection,
    setPage,
    handleOpenBackdrop,
    handleCloseBackdrop,
    statusUpdateUrl,
    rows,
  } = props;
  const numSelected = selected?.length;
  const { onCancel, onSave, placeholder, addButtonName, getPayload, fetchData } = toolbarProps;
  const handleStatusChange = (event) => {
    toolbarProps?.onStatusChange(event);
    setPage(0);
  };
  const params = useParams();
  const dispatch = useDispatch();
  const { id } = params;
  const [isSystemModalOpen, setSystemModalOpen] = useState(false);
  const {
    categories: systemElements,
    isLoading: isSystemLoading,
    isAdding: isSystemAdding,
  } = useSelector((state) => state.category);
  const { singleProjectData, isSingleProjectLoading, isAdding } = useSelector((state) => state.project);
  useEffect(() => {
    dispatch(getCategories({ defaultEntry: true }));
  }, [dispatch]);
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const Role = jwtToken && jwtDecode(jwtToken);
  const role = String(Role?.role) || '0';
  const els = useMemo(
    () =>
      systemElements
        ?.map((el) => ({ label: el?.name, value: el?._id }))
        ?.filter((element) => !rows?.some((row) => row?._id === element?.value)),
    [rows, systemElements]
  );
  const systemCategoryOptions = systemElements
    ?.filter((el) => !rows?.some((row) => row._id === el._id))
    ?.map((element) => ({ label: element?.name, value: element?._id }));

  const form2 = useForm({
    defaultValues: {
      categoryID: '',
    },
    // resolver: yupResolver(addSystemElementSchema),
  });
  const handleSelectionClick = async (selectedIds, status) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (statusUpdateUrl && selectedIds && selectedIds?.length > 0) {
      const initialPayload = { areaIDs: selected, status, projectID: id };
      handleOpenBackdrop();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: statusUpdateUrl,
          method: 'PATCH',
          data: getPayload ? getPayload(selected, status, id) : initialPayload,
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
              // snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Area`,
            })
          );
          handleClearSelection();
          if (fetchData) {
            fetchData();
          }
          // dispatch(getAreas({ projectID: id }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const closeSystemModal = () => {
    setSystemModalOpen((prevState) => !prevState);
    form2.reset();
  };
  const canAddSystemCategory = useMemo(() => {
    if (singleProjectData?.addSystemElement && role === '1') {
      return true;
    }
    return false;
  }, [role, singleProjectData]);
  const onElementSubmit = (values) => {
    const currentSystemCategory = systemElements?.find((element) => element?._id === values?.categoryID);
    if (currentSystemCategory) {
      const payload = {
        defaultEntry: false,
        name: currentSystemCategory?.name,
        projectID: singleProjectData?._id,
        customerID: singleProjectData?.customerID,
        analysisType: get(currentSystemCategory, 'projectSetting.analysisType'),
        mainCategory: get(currentSystemCategory, 'projectSetting.mainCategory'),
      };

      if (singleProjectData?.customerID && singleProjectData?._id) {
        dispatch(addCategory(payload))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Category Added Successfully'),
                })
              );
              fetchData();
              closeSystemModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  return (
    <>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px !important',
          },
        }}
        title="Add System Category"
        open={isSystemModalOpen}
        onClose={closeSystemModal}
        DialogActions={
          <DialogActions sx={{ mt: 2, p: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeSystemModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={false} onClick={form2?.handleSubmit(onElementSubmit)} type="submit" variant="contained">
              Save Changes
            </ThemeButton>
          </DialogActions>
        }
      >
        <form onSubmit={form2?.handleSubmit(onElementSubmit)}>
          <label htmlFor="categoryID" id="category-select" style={{ fontSize: '0.8600rem' }}>
            Category
          </label>
          <CustomSelect
            width="90%"
            name="categoryID"
            control={form2?.control}
            options={systemCategoryOptions}
            label="Select a Category"
            error={!!form2?.formState?.errors?.categoryID}
            helperText={form2?.formState?.errors?.categoryID && form2?.formState?.errors?.categoryID?.message}
          />
        </form>
      </ModalComponent>
      <Toolbar
        sx={{
          minHeight: 76,
          py: 0,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between',
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
            </Typography>
            <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
          </Stack>
        ) : null}
        {numSelected > 0 ? (
          <Stack gap direction="row" whiteSpace="nowrap">
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ARCHIVED ? (
              <ActionButton
                type="activate"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                onClick={() => handleSelectionClick && handleSelectionClick(selected, 'active', handleClearSelection)}
              >
                {`Activate ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ACTIVE ? (
              <ActionButton
                type="archive"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                color="error"
                onClick={() => handleSelectionClick && handleSelectionClick(selected, 'archive', handleClearSelection)}
              >
                {`Archive ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
          </Stack>
        ) : (
          <Grid p={0} container alignItems="center" justifyContent="space-between" spacing>
            <Grid item lg={4} xl={3} md={4} xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  sx={{
                    fontSize: '0.86rem',
                    borderRadius: 25,
                    lineHeight: '1.9375em',
                    '.MuiInputBase-input': {
                      height: '0.860em',
                      py: '15px',
                    },
                  }}
                  fullWidth
                  value={filterName}
                  onChange={onFilterName}
                  placeholder={searchLable || placeholder || 'Search customer name...'}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid justifyContent={{ lg: 'flex-start', xl: 'flex-end' }} item xs={12} md={6}>
              <Stack
                gap={3}
                width="100%"
                sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
                direction="row"
                alignItems="center"
              >
                {canAddSystemCategory && (
                  <ThemeButton
                    onClick={() => setSystemModalOpen(true)}
                    size="small"
                    startIcon={<AddCircleOutlineIcon />}
                    variant="outlined"
                  >
                    Add System Category
                  </ThemeButton>
                )}
                <ThemeButton
                  onClick={toolbarProps?.openModal && toolbarProps?.openModal}
                  size="small"
                  startIcon={<AddCircleOutlineIcon />}
                  variant="outlined"
                >
                  {addButtonName || 'Add Category'}
                </ThemeButton>
                {/* <Typography>Status</Typography> */}
                <StatusButtons
                  status={toolbarProps?.status}
                  onStatusChange={handleStatusChange}
                  fullWidth
                  size="small"
                  // sx={{ width: '80%', mt: '5px', mb: '16px' }}
                  sx={{ width: '40%' }}
                />
              </Stack>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </>
  );
};

CategoriesToolbar.propTypes = {
  selected: PropTypes.array,
  // handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string,
  statusUpdateUrl: PropTypes.string,
  onFilterName: PropTypes.func,
  setPage: PropTypes.func,
  handleClearSelection: PropTypes.func,
  handleOpenBackdrop: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
  searchLable: PropTypes.string,
  toolbarProps: PropTypes.object,
  rows: PropTypes.array,
};

export default CategoriesToolbar;
