import { Chip, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStudyColor, getStudyLabel } from '../../components/StudiesComponent';
import DataTable from '../../components/table';
import { getNotes } from './notesSlice';
import { Types } from '../../types';

const duration = require('dayjs/plugin/duration');
const utc = require('dayjs/plugin/utc'); // For handling UTC dates

dayjs.extend(utc);
dayjs.extend(duration);

const defaultStudyPageColumnSettings = {
  studyType: true,
  studyName: true,
  timeAndDate: true,
  locationName: true,
  areaName: true,
  taskName: true,
  elementName: true,
  time: true,
  bms: true,
  notes: true,
  image: true,
};
const NotesPage = ({ projectID }) => {
  const { notes, isLoading } = useSelector((state) => state.notes);
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filteredColSettings, setFilteredColSettings] = useState(defaultStudyPageColumnSettings);
  const [filterConfig, setFilterConfig] = useState({
    filterBy: '',
    filterColumns: [],
    sortOrder: '',
    sortBy: '',
  });
  const openFilter = (key, orderBy) => {
    setIsFilterOpen(true);
    setFilterBy(key);
    if (orderBy) {
      setSortBy(orderBy);
    }
  };
  const onEditClick = (event, row, nestedCollapsedRows, setNestedCollapsedRows) => {
    if (nestedCollapsedRows.indexOf(row?._id) !== -1) {
      setNestedCollapsedRows(nestedCollapsedRows.filter((collapsedRow) => collapsedRow !== row?._id));
    } else {
      setNestedCollapsedRows((prevRows) => [row?._id]);
    }
  };

  const closeFilter = () => setIsFilterOpen(false);
  const headCells = useMemo(
    () => [
      {
        id: 'studyType',
        disablePadding: false,
        label: 'Study Type',
        // minWidth: 180,
        renderElement: (cell) => (
          <Typography sx={{ color: getStudyColor(cell.studyType) }} variant="subtitle2">
            {getStudyLabel(cell.studyType)}
          </Typography>
        ),
        onClick: () => openFilter('studyType', 'studyType'),
        minWidth: 130,
      },

      {
        id: 'studyName',
        numeric: false,
        label: 'Study Name',
        onClick: () => openFilter('studyName', 'studyName'),
        minWidth: 130,
      },
      {
        id: 'isEc',
        // maxWidth: 36,
        align: 'left',
        renderElement: (cell) =>
          cell?.isEc ? (
            <Typography
              sx={{
                backgroundColor: 'primary.main',
                color: '#fff',
                ml: '15px',
                padding: '3px 6px',
                borderRadius: '21px',
                width: '27px',
              }}
              fontSize="small"
            >
              EC
            </Typography>
          ) : null,
      },
      {
        id: 'formatedStartTime',
        numeric: false,
        label: 'Time and Date',
        renderElement: (cell) => (
          <Stack>
            <Typography variant="subtitle2">{`${get(cell, 'formatedStartTime')} - ${get(cell, 'formatedEndTime')}`}</Typography>
            <Typography variant="subtitle2">{get(cell, 'formatedDate')}</Typography>
          </Stack>
        ),
        minWidth: 150,
      },
      {
        id: 'areaName',
        numeric: false,
        label: 'Area',
        // minWidth: 170,
        // onClick: () => openFilter('areaName', 'areaName'),
      },
      {
        id: 'taskName',
        disablePadding: false,
        label: 'Task',
        // renderElement: (cell) => <StudiesComponent studyTypes={cell?.studyTypes} />,
      },
      {
        id: 'elementName',
        numeric: false,
        label: 'Element Name',
        // minWidth: 120,
        // onClick: () => openFilter('elementName', 'elementName'),
      },
      {
        id: 'observationStartTime',
        numeric: false,
        label: 'Time',
      },
      {
        id: 'bms',
        numeric: false,
        label: 'BMS',
        renderElement: (cell) =>
          cell?.bms && (
            <Stack alignItems="center" justifyContent="center" sx={{ pr: 2 }}>
              <Chip size="small" sx={{ minWidth: 70, bgcolor: cell?.bmsFormatting || '' }} label={cell?.bms} />
            </Stack>
          ),
        align: 'center',
        minWidth: 150,
        // onClick: () => openFilter('bms', 'bms'),
      },
      {
        id: 'notes',
        numeric: false,
        label: 'Notes',
        // minWidth: 250,
      },
      {
        id: 'image',
        numeric: false,
        label: 'Images',
      },
    ],
    []
  );
  const fetchNotes = () => {
    if (projectID) {
      dispatch(getNotes({ projectID }));
    }
  };
  useEffect(() => {
    if (projectID) {
      // dispatch(getRoles({ projectID }));
      // dispatch(getAreas({ projectID }));
      // dispatch(getElements({ projectID, defaultEntry: false }));
      // dispatch(getTasks({ projectID }));
      dispatch(getNotes({ projectID }));
    }
  }, [dispatch, projectID]);
  const nestedRowProps = {
    fetchNotes,
    nestedType: 'nestedNotes',
  };
  const notesRows = useMemo(() => {
    if (notes && notes?.length > 0) {
      return notes?.map((note) => {
        const formatedStartTime = dayjs(note?.startTime).format('HH:mm');
        const formatedEndTime = dayjs(note?.endTime).format('HH:mm');
        const formatedDate = dayjs(note?.startTime).format('DD/MM/YYYY');
        let formattedTime = '';
        let observationStartTime = '';
        if (note?.roundStartTime) {
          const dateTime = dayjs(note?.roundStartTime); // Ensure it's interpreted as UTC
          formattedTime = dateTime.format('HH:mm');
        }
        if (note?.startTime) {
          const dateTime = dayjs(note?.startTime).utc(); // Ensure it's interpreted as UTC
          // Format the time to hours and minutes
          const formattedStartTime = dateTime.format('HH:mm');
          // observationStartTime = formattedTime;
          observationStartTime = formattedStartTime;
        }
        const isEc = get(note, 'originalValues') && Object.keys(get(note, 'originalValues')).length > 0;
        return {
          ...note,
          startTime: formattedTime,
          bms: get(note, 'bms')?.toString(),
          frequency: get(note, 'frequency')?.toString(),
          bmsPerUom: get(note, 'bmsPerUom')?.toString(),
          _id: note?.observationID,
          roundID: note?.roundID,
          observationStartTime,
          formatedStartTime,
          formatedDate,
          formatedEndTime,
          isEc,
        };
      });
    }
    return [];
  }, [notes]);
  const toolbarProps = {
    setFilterConfig,
  };
  return (
    <div>
      <DataTable
        filterKeys={['taskName', 'elementName', 'startTime', 'studyName', 'areaName']}
        customToolbar={Types.toolbarTypes.NOTES}
        headCells={headCells}
        rows={notesRows}
        filterColumns={filterConfig?.filterColumns}
        filterBy={filterConfig?.filterBy}
        sortOrder={filterConfig?.sortOrder}
        sortBy={filterConfig?.sortBy}
        setElementFilterConfig={setFilterConfig}
        // customColumnType={study?.studyType}
        nestedRowKey={Types.nestedRowTypes.NEST_ROW}
        nestedRowProps={nestedRowProps}
        customColumnType="studyType"
        disableNestedRowClick
        hideNestedArrow
        onEditClick={onEditClick}
        isLoading={isLoading}
        toolbarProps={toolbarProps}
        // onRowDelete={onDelete}
        enableDelete
        defaultRowsPerPage={10}
        hideCompactViewButton
        disableCheckbox
        // onSettingsClick={() => setColumnsModal(true)}
      />
    </div>
  );
};

NotesPage.propTypes = { projectID: PropTypes.string };

export default NotesPage;
