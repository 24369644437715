import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogActions, Grid } from '@mui/material';
import { get, has, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../components/ModalComponent';
import { ThemeButton } from '../../components/button';
import FormInput from '../../components/formComponents/FormInput';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import StatusIndicator from '../../components/status';
import DataTable from '../../components/table';
import { Types } from '../../types';
import axiosInstance from '../../utils/axiosInstance';
import { addGroupSchema } from '../../utils/schema';
import { addGroup, getGroups, updateGroup } from './groupSlice';

const GroupRulesPage = ({ projectID, customerID }) => {
  const dispatch = useDispatch();
  const headCells = [
    {
      id: 'name',
      numeric: false,
      // disablePadding: true,
      label: 'Group Name',
      // titleCase: true,
      // minWidth: '240px',
      // align: 'left',
    },
    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => has(cell, 'projectSetting.status') && <StatusIndicator cell={cell?.projectSetting} />,
      // width: '15%',
    },
  ];
  useEffect(() => {
    dispatch(getGroups({ projectID }));
  }, [dispatch, projectID]);
  const { groups, isLoading, addGroupLoading } = useSelector((state) => state.groups);
  const [groupRows, setGroupRows] = useState([]);
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [isModalopen, setisModalopen] = useState(false);
  const [Status, setStatus] = useState(ACTIVE);
  const [rowToEdit, setRowToEdit] = useState('');
  const form = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(addGroupSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const openModal = () => setisModalopen(true);
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    reset();
    // reset({ permission: '' });
    // setShowPassword(false);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const regularGroups = useMemo(
    () =>
      !isEmpty(groups) &&
      !!groups?.length &&
      groups.reduce(
        (acc, group) => {
          const groupObject = {
            ...group,
            status: get(group, 'projectSetting.status'),
          };
          if (group?.projectSetting?.status === 'active') {
            acc.active.push(groupObject);
          } else if (group?.projectSetting?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(groupObject);
          }
          acc.default.push(groupObject);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [groups]
  );
  useEffect(() => {
    if (regularGroups) {
      if (Status === DEFAULT) {
        setGroupRows(regularGroups?.default);
      } else if (Status === ARCHIVED) {
        setGroupRows(regularGroups?.archive);
      } else if (Status === ACTIVE) {
        setGroupRows(regularGroups?.active);
      }
    } else {
      setGroupRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularGroups]);

  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setGroupRows(regularGroups.default);
      } else if (value === ACTIVE) {
        setGroupRows(regularGroups.active);
      } else if (value === ARCHIVED) {
        setGroupRows(regularGroups.archive);
      }
    }
  };
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search Groups',
    openModal,
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'groups/update/status',
          method: 'PATCH',
          data: { groupIDs: [row?._id], status, projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Group`,
            })
          );
          dispatch(getGroups({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onEditClick = async (event, row) => {
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      const groupToEdit = groups.find((group) => group?._id === row?._id);
      setValue('name', get(groupToEdit, 'name', ''));
      openModal();
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `groups/delete/${row?._id}`,
          method: 'DELETE',
          data: { projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getGroups({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onSubmit = (values) => {
    const { defaultCategory, ...rest } = values;
    const payload = { ...rest };
    if (defaultCategory && defaultCategory !== '') {
      payload.defaultCategory = defaultCategory;
    }
    if (customerID && projectID) {
      if (rowToEdit) {
        dispatch(updateGroup({ ...payload, customerID, projectID, _id: rowToEdit }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Group Updated Successfully'),
                })
              );
              dispatch(getGroups({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      } else {
        dispatch(addGroup({ ...payload, customerID, projectID }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Group Added Successfully'),
                })
              );
              dispatch(getGroups({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  return (
    <Box>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '600px !important',
            maxHeight: '700px',
          },
        }}
        title={rowToEdit ? 'Edit Group' : 'Create Group'}
        // title="Add Area"
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <Grid columnSpacing={3} rowSpacing={2} container justifyContent="space-between">
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <FormInput
                  label="Name"
                  placeholder="Enter Name"
                  InputProps={{
                    ...register('name'),
                  }}
                  error={!!errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              </Grid>
            </Grid>
          </Box>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={addGroupLoading} type="submit" variant="contained">
              {/* {rowToEdit ? 'Save Changes' : 'Create industry'} */}
              Save Changes
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        rows={groupRows}
        searchLable="Search Groups"
        onRowStatusChange={onRowStatusChange}
        onEditClick={onEditClick}
        filterKeys={['name']}
        customToolbar={Types.toolbarTypes.GROUPS}
        maxHeight={453}
        toolbarProps={toolbarProps}
        hideCompactViewButton
        onRowDelete={onDelete}
        defaultRowsPerPage={10}
      />
    </Box>
  );
};

export default GroupRulesPage;
GroupRulesPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};
